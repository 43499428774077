.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 3.75rem;
  color: rgba(255, 255, 255, 0.9);
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);

  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

div.number {
  display: inline;
  line-height: inherit;
  font-size: inherit;
  color: inherit;

  border-bottom: 3px solid yellow;
}

input.number {
  display: inline;
  border: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  /* line-height: inherit; */
  font-size: inherit;
  color: inherit;
  text-align: center;

  background-color: rgba(100, 100, 100, 0.4);
  border-bottom: 3px solid yellow;
}

input.number:focus {
  outline: none;
}

input.number:invalid {
  border-bottom: 3px solid #ff5722;
}
