.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.9);
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.ball {
  /* Move ball classes here */
}

.basket {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}
