.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background 0.2s ease;
  min-height: 28px;
}

.label:hover {
  cursor: pointer;
  background: rgba(220, 220, 220, 0.4);
}

.placeholder {
  color: #999;
}

.readonly {
  cursor: auto !important;
}

.readonly:hover {
  cursor: auto !important;
}
